<template>
    <div>
        <is-field :horizontal="horizontal" grouped>
            <button class="button is-primary" @click="copyData()">
                Von eingeloggtem Benutzer kopieren
            </button>
        </is-field>

        <is-user-fields :user-data="user" @invalid="updateValue('user_data_invalid', $event)"
                        @input="updateValue('form_data', $event)" :horizontal="horizontal"/>
    </div>

</template>

<script>
    import {required, maxLength, minLength, email, numeric} from 'vuelidate/lib/validators'
    import _ from 'lodash'
    import {getFieldErrorMessage, getOrgNamesArray, getPreloadData} from '../js/tools'
    import IsField from "./buefy-overrides/Field.vue";

    let preloadData = getPreloadData();

    export default {
        components: {IsField},
        props: {
            horizontal: {},
            userData: {}
        },
        data() {
            return {
                loading: false,
                logged_in: preloadData.logged_in,
                user: this.userData,
                user_data_invalid: true,
            };
        },
        methods: {
            updateValue(key, val) {
                console.log('contact-person-form::event update', key, val);
                this[key] = val;
                if (key === 'form_data') {
                    this.$emit('input', val)
                }
                if (key === 'user_data_invalid') {
                    this.$emit('invalid', val);
                }
            },
            copyData() {
                console.log(this.user, preloadData.user);
                let newData = _.assign({}, _.omit(preloadData.user,['__v', '_id']));
                console.log({newData});
                this.user = newData;
            },
            check_errors(name) {
                let $v = this.$v.form_data[name];
                let input_field_meta = this.form_meta[name];
                let params = $v.$flattenParams();
                for (let p of params) {
                    let error_value = $v[p.name];
                    let hasError = !error_value;
                    if (hasError) {
                        input_field_meta.error = getFieldErrorMessage(p);
                        return;
                    }
                }
                input_field_meta.error = '';

            }
        },
        computed: {
            is_loading_class() {
                return this.loading ? 'is-loading' : ''
            },
        }
    }
</script>

<style lang="sass"></style>
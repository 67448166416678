<template>
    <div v-cloak>
        <div v-if="!logged_in">
            <b-field>
                <b-input type="password" password-reveal placeholder="Zugangscode" v-model="code"
                         @input="$v.code.$touch()" ></b-input>
                <p class="control">
                    <button class="button is-primary" v-on:click="login()" :disabled="!valid_input">Log In</button>
                </p>
            </b-field>
        </div>
        <div v-else>
            <button class="button is-warning" v-on:click="logout()">Log Out</button>
        </div>
    </div>
</template>

<script>
    import {required} from 'vuelidate/lib/validators'
    import {getPreloadData} from '../js/tools'
    import {trim} from 'lodash'
    let preload = getPreloadData();


    export default {
        data() {
            return {
                // code: 'EJOuDfTyN',
                code: trim(window.location.hash, '#/'),
                user: preload.user,
            }
        },
        computed: {
            valid_input() {
                return !this.$v.code.$error
            },
            logged_in() {
                return !!this.user
            },
        },
        validations: {
            code: {required}
        },
        methods: {
            logout() {
                this.$http.get('/api/user/logout').then(
                    (x) => {
                        window.location = '/'
                    },
                    (x) => {
                        console.log('error logging out', x);
                        this.$snackbar.open("Es gab einen Fehler beim Ausloggen!");
                    });
            },
            login() {
                let loadingComponent = this.$loading.open();
                this.$http.post('/api/user/login', {code: this.code}).then(
                    (x) => {
                        window.location = '/account'
                    },
                    (x) => {
                        console.log('error', x);
                        this.$snackbar.open("Zugangscode falsch!");
                        this.code = '';
                        loadingComponent.close();
                    });
            }
        },
        mounted() {
            if(this.code) {
                this.login();
            }
        }
    }


</script>

<style lang="sass"></style>
<template lang="pug">
    include ../../views/mixins/bulma
    div
        div(v-if="has_error_messages")
            p(v-for="m in error_messages")
                info-text(kind="danger") {{m}}
        div(v-else)
            info-text(kind="success") Angaben regelkonform
</template>

<script>
    import {getFieldErrorMessage, getOrgNamesArray, getPreloadData} from '../js/tools'
    import InfoText from "./info-text";

    export default {
        components: {InfoText},
        props: ['value'],
        data() {
            return {}
        },
        computed: {
            error_messages() {
                let $v = this.value;
                if(!$v) {
                    console.warn('show-errors without value');
                    return [];
                }
                let errors = [];

                let params = $v.$flattenParams();


                for (let p of params) {
                    let v_error_path = _.concat(p.path,[p.name]);
                    let error_value = _.get($v, v_error_path);
                    let hasError = !error_value;
                    if (hasError) {
                        errors.push(getFieldErrorMessage(p));
                    }
                }
                return _.uniq(errors);
            },
            has_error_messages() {
                return this.error_messages.length > 0
            }
        }
    }

</script>
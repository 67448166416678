<template>
    <is-field :horizontal="horizontal" :label="meta.label" :type="warning_type" :message="meta.error" :required="meta.required">
        <b-input v-if="!autocomplete" ref="input" :type="meta.type" :value="local_value" @input="updateValue($event)" :required="meta.required"
                 :password-reveal="meta.pw_reveal" :disabled="meta.disabled"></b-input>
        <b-autocomplete v-else :value="local_value" :data="filteredDataArray" placeholder="e.g. TU Graz"
                        :loading="loading" icon="search" @input="updateValue($event)" ref="autocomplete">
            <template slot="empty">Keine passenden Einträge! Bitte verwenden Sie einen Freitext!</template>
        </b-autocomplete>
    </is-field>
</template>

<script>
    import {get, isUndefined} from 'lodash';

    export default {
        data() {
            return {}
        },
        props: {
            horizontal: {},
            value: {required: true},
            loading: {},
            meta: {
                required: true,
            },
            autocomplete: {
                required: false,
                type: Array
            },
            passwordReveal: {}
        },
        methods: {
            updateValue(new_value) {
                if (!this.meta.loading) {
                    this.$emit('input', new_value);
                }
            }
        },
        computed: {
            local_value() {
                return this.value
            },
            local_error() {
                return this.meta.error
            },
            is_dirty() {
                return get(this.meta, 'dirty', false)
            },
            has_error() {
                if (isUndefined(this.local_error)) return false;
                return this.local_error !== '';
            },
            warning_type() {
                if (!this.is_dirty) return '';
                return this.has_error ? 'is-warning' : 'is-success'
            },
            filteredDataArray() {
                if (!this.value) return [];
                return this.autocomplete.filter((option) => {
                    return option
                        .toString()
                        .toLowerCase()
                        .indexOf(this.value.toLowerCase()) >= 0
                })
            }
        },
        created() {

        }
    }
</script>
<template>
  <div class="field">
    <label class="label">{{ label }}</label>
    <div class="control">
      <textarea class="textarea" :value="newValue.content" @input="onInput" rows="10"></textarea>
      <div class="help counter">
        <span class="help" :class="field_class">{{ current_length_chars }} / {{ minLength }} Zeichen</span> <span
          class="help"><span class="is-warning">{{ current_length_lines }} Zeilen</span></span>
      </div>
      <br>
      <button class="button" @click="showPreview()">Analyse der Zeilen</button>
    </div>
    <b-modal :active.sync="isModalActive" scroll="keep" has-modal-card>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Analyse der Zeilen</p>
          <button class="delete" aria-label="close" @click="isModalActive = false"></button>
        </header>

        <section class="modal-card-body">
          <div class="preview-area">
            <div v-for="r of textLineStats.rinfos" class="preview-row-info">
              <div class="row-number">{{ r.index }}:&nbsp;</div>
              <div class="row-content">
                <span class="row-text" v-html="r.html"></span>
              </div>
            </div>
          </div>
        </section>

      </div>
    </b-modal>
  </div>

</template>

<script>
import _ from 'lodash'
import {calculateLengthRows} from '../../js/tools'

export default {
  name: 'skTextarea',
  data() {
    return {
      newValue: this.value,
      previewText: '',
      isModalActive: false,
      analysisMode: 'preview',
    }
  },
  props: {
    label: {
      required: false
    },
    value: {required: true},
    minLength: {
      default: 1000
    },
    maxLength: {
      default: 2000
    }
  },
  watch: {
    value(val) {
      this.newValue = val
    },

    newValue: {
      handler(val) {
        this.$emit('input', val);
      },
      deep: true
    },
    current_length_lines(val) {
      this.newValue.rows = val ?? 0;
    },
    current_length_chars(val) {
      this.newValue.len = val ?? 0;
    }
  },
  methods: {
    /**
     * Input's 'input' event listener, 'nextTick' is used to prevent event firing
     * before ui update, helps when using masks (Cleavejs and potentially others).
     */
    onInput(event) {
      this.$nextTick(() => {
        this.newValue.content = event.target.value;
      })
    },
    showPreview(mode) {
      this.analysisMode = mode;
      this.isModalActive = true;
    }
  },
  computed: {
    current_length_lines() {
      return this.textLineStats?.total_lines || 0
    },
    current_length_chars() {
      return this.textLineStats?.total_chars || 0
    },
    field_class() {
      return this.current_length_chars >= this.minLength && this.current_length_chars <= this.maxLength ? 'is-success' : 'is-warning';
    },
    textLineStats() {
      const content = _.trim(this.newValue?.content || '');
      const parts = content.split(/\n/g);


      let rows = [];
      for (const p of parts) {
        if (!p.length) {
          rows.push('');
        } else {
          rows = rows.concat(p.match(/.{1,98}/g))
        }
      }

      console.log({parts, rows});

      let r_total_lines = 0;
      let r_total_chars = 0;
      const rinfos = rows.map((r, idx) => {
        let html = r
        if (r.length < 98) {
          html += '<span class="visible-newline">↵</span>';
        }
        return {
          index: (idx + 1).toString().padStart(2, '0'),
          text: r,
          html: html,
          chars: r.length,
          running_total_lines: r_total_lines++,
          running_total_chars: r_total_chars += r.length,
        }
      })

      return {
        total_lines: r_total_lines,
        total_chars: content.length,
        rinfos,
      };
    }
  },
}
</script>
<template>
  <div class="info_text_wrapper" v-if="hasDefaultSlot || trkey_exists || is_admin">

    <div class="info_text">

      <div class="info_text-icon"><span class="icon" :class="type_class"><i class="fas" :class="type_icon"></i></span>
      </div>

      <div class="info_text-content" v-if="trkey !== ''">
        <tr-text :trkey="trkey">
          <slot></slot>
        </tr-text>
      </div>

      <div class="info_text-content" v-if="trkey === ''">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import {getFieldErrorMessage, getOrgNamesArray, getPreloadData, translations} from '../js/tools'

let preloadData = getPreloadData();

const type_icons = {
  warning: 'exclamation-triangle',
  info: 'info-circle',
  danger: 'exclamation-triangle',
  success: 'check',

};
const type_classes = {
  warning: 'warning',
  info: 'ccca_poolblau',
  success: 'success',
  danger: 'danger',
};

export default {
  name: 'infoText',
  props: {
    kind: {
      type: String,
      default: 'info',
    },
    trkey: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      is_admin: preloadData.is_admin,
      translations: {}
    }
  },
  computed: {
    type_icon() {
      let typeIcon = type_icons[this.kind];
      return 'fa-' + typeIcon;
    },
    type_class() {
      let typeClass = type_classes[this.kind];
      return `has-text-${typeClass}`
    },
    trkey_exists() {
      return !!this.translations[this.trkey]
    },
    hasDefaultSlot () {
      return !!this.$slots.default
    },
  },
  created() {
    translations.then(x => this.translations = x)
  },

}

</script>

<style lang="sass" scoped>
.info_text_wrapper

  margin-left: 2rem

  .info_text
    background-color: #f8f8f8
    /*border-left: 1px #888 solid*/
    border-right: 1px #888 solid
    position: relative
    margin-left: 1rem
    margin-bottom: 0.5rem
    padding: 0.5rem
    border-radius: 5px

    .info_text-icon
      position: absolute
      left: -1.7rem

  &:last-child
    margin-bottom: 1.5rem

</style>
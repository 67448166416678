<template lang="pug">
    a(@click="del()")
        i.fas.fa-trash(title="Löschen")
</template>

<script>
    export default {
        props: {
            kind: {required: true},
            value: {required: true}
        },
        data() {
            return {}
        },
        computed: {
            delete_url() {
                return `/api/${this.kind}/${this.value}`
            }
        },
        methods: {
            del() {
                console.log('delete item', this.delete_url);
                this.$dialog.confirm({
                    title: 'Deleting account',
                    message: 'Sind Sie sicher, dass Sie diesen Eintrag <b>löschen</b> möchten? Dieser Vorgang kann nicht rückgängig gemacht werden!',
                    confirmText: 'Löschen',
                    type: 'is-warning',
                    hasIcon: true,
                    onConfirm: () => {
                        this.$http.delete(this.delete_url)
                            .then(() => {
                                location.reload()
                            }, (err) => {
                                this.$toast.open({
                                    message: `Fehler beim Löschen!`,
                                    type: 'is-danger'
                                })
                            });
                    }
                })
            }
        }
    }
</script>
<template>
    <b-autocomplete :value="local_value" :data="filteredDataArray" :placeholder="placeholder" icon="search"
                    @input="updateValue($event)">
        <template slot="empty">Keine passenden Einträge! Bitte verwenden Sie einen Freitext!</template>
    </b-autocomplete>
</template>

<script>
    export default {
        data() {
            return {}
        },
        props: {
            horizontal: {},
            value: {required: true},
            placeholder: {},
            autocomplete: {
                required: false,
                type: Array
            },
        },
        methods: {
            updateValue(new_value) {
                this.$emit('input', new_value);
            }
        },
        computed: {
            local_value() {
                return this.value
            },
            filteredDataArray() {
                if (!this.value) return [];
                return this.autocomplete.filter((option) => {
                    return option
                        .toString()
                        .toLowerCase()
                        .indexOf(this.value.toLowerCase()) >= 0
                })
            }
        },
    }
</script>
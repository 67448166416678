<template>
  <div v-cloak="v-cloak">
    <h2 class="title is-2" v-if="!logged_in">Account Erstellen</h2>
    <h2 class="title is-2" v-if="logged_in">Account Bearbeiten</h2>
    <div class="div" v-if="active || logged_in || is_admin">
      <is-user-fields :user-data="user" @invalid="updateValue('user_data_invalid', $event)"
                      @input="updateValue('form_data', $event)"></is-user-fields>
      <is-field v-if="logged_in" label="Zugangscode">
        <b-input disabled="" :value="user.access_code"></b-input>
      </is-field>
      <is-field grouped="">
        <button class="button is-primary" :class="is_loading_class" :disabled="user_data_invalid" @click="saveData()">
          <span v-if="!logged_in">Anmeldung</span><span v-else="">Daten speichern</span></button>
      </is-field>
    </div>
    <div class="div" v-else>
      <p><b>Die Anmeldefrist für den ist bereits abgelaufen.</b></p>
    </div>
  </div>
</template>

<script>
import {getFieldErrorMessage, getPreloadData} from '../js/tools'
import IsField from "./buefy-overrides/Field.vue";

let preloadData = getPreloadData();

export default {
  components: {IsField},
  data() {
    return {
      loading: false,
      active: preloadData.active.registration,
      logged_in: preloadData.logged_in,
      is_admin: preloadData.is_admin,
      user: preloadData.user,
      user_data_invalid: true,
      form_data: {},
    };
  },
  methods: {
    updateValue(key, val) {
      console.log('event update', key, val);
      this[key] = val
    },
    saveData() {
      console.log('save data!', this.form_data);
      this.loading = true;

      let success = (response) => {
        if (this.logged_in) {
          this.$toast.open("Daten gespeichert!");
          return;
        }
        location.reload();
      };
      let failure = (response) => {
        this.$toast.open("Fehler beim speichern!", 'is-danger');
      };
      this.$http.post('/api/user', this.form_data).then(success, failure).then((x) => {
        this.loading = false;
      });
    },
    check_errors(name) {
      let $v = this.$v.form_data[name];
      let input_field_meta = this.form_meta[name];
      let params = $v.$flattenParams();
      for (let p of params) {
        let error_value = $v[p.name];
        let hasError = !error_value;
        if (hasError) {
          input_field_meta.error = getFieldErrorMessage(p);
          return;
        }
      }
      input_field_meta.error = '';

    }
  },
  computed: {
    is_loading_class() {
      return this.loading ? 'is-loading' : ''
    }
  }
}
</script>

<style lang="sass"></style>
<template lang="pug">
  div
    h2.title.is-2 Export
    .field.is-grouped
      p.control
        button.button.is-info(@click="dlExport('atts', 'xlsx')") Teilnahmen XLSX
      p.control
        button.button.is-info(@click="dlExport('subs', 'xlsx')") Einreichungen XLSX
      p.control
        button.button.is-info(@click="dlExport('pdfs', 'pdf')") Einreichungen ZIP

    //
        h2.title.is-2 Liste neu
        .filters
            .field
                b-checkbox(v-model='filters.has_submissions_or_drafts')
                    | Nur mit Einreichungen (inkl. Entwürfe)
            .field
                b-checkbox(v-model='filters.has_submissions')
                    | Nur mit bestätigten Einreichungen
            .field
                b-checkbox(v-model='filters.has_attendances')
                    | Nur mit Teilnahmen
            .field
                b-checkbox(v-model='filters.has_paid_attendances')
                    | Nur mit bezahlten Teilnahmen
        b-table(:data='filtered_users', paginated, per-page='10',detailed, detail-key='access_code')
            template(slot-scope='props')
                b-table-column(field='access_code', label='Code')
                    | {{ props.row.access_code }}
                b-table-column(field='first_name', label='First Name', sortable)
                    | {{ props.row.first_name }}
                b-table-column(field='last_name', label='Last Name', sortable)
                    | {{ props.row.last_name }}
                b-table-column(field='org', label='Org', sortable)
                    | {{ props.row.org }}
                b-table-column(:label="'<b>Org</b>'", sortable)
                    | {{ props.row.org }}
            template(slot='detail', slot-scope='props')
                article.media
                    .media-content
                        .content
                            p
                                strong {{ props.row.access_code }} {{ props.row.last_name }}
                                small @{{ props.row.first_name }}
                                small 31m
                                br
                                |                             Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                |                             Proin ornare magna eros, eu pellentesque tortor vestibulum ut.
                                |                             Maecenas non massa sem. Etiam finibus odio quis feugiat facilisis.

    h2.title.is-2 Liste
    .filters
      .field
        b-checkbox(v-model='filters.has_submissions_or_drafts')
          | Nur mit Einreichungen (inkl. Entwürfe)
      .field
        b-checkbox(v-model='filters.has_submissions')
          | Nur mit bestätigten Einreichungen
      .field
        b-checkbox(v-model='filters.has_attendances')
          | Nur mit Teilnahmen
      .field
        b-checkbox(v-model='filters.has_paid_attendances')
          | Nur mit bezahlten Teilnahmen

    .field
    b-field
      b-input(placeholder='Search...', type='search', icon='search', v-model="filters.text")
    .field

    table.table.is-narrow.is-fullwidth.main
      thead
        tr
          th Name
          th Org

          th
            abbr(title="Einreichungen") Ei
          th
            abbr(title="Einreichungen Bestätigt") EiB
          th
            abbr(title="Teilnahmen") Tn
          th
            abbr(title="Teilnahmen Bezahlt") TnB
          th Login
      tbody
        template(v-for="u in filtered_users")
          tr(@click="set_active(u)", :class="{'is-selected': active_user === u._id}")
            td {{u.first_name + ' ' + u.last_name}}
            td {{u.org}}
            td {{u.subs.length}}
            td {{user_count_submitted(u)}}
            td {{u.atts.length}}
            td {{user_count_paid(u)}}
            td
              a(:href="get_access_link(u)") {{u.access_code}}
          tr(v-if="active_user === u._id").inner_table
            td(colspan=4)
              .highlight
                p.light.has-text-centered Einreichungen
                table.table.is-narrow.is-fullwidth(v-if="u.subs.length > 0")
                  thead
                    tr
                      th Id
                      th Titel
                      th Einr. Typ
                      th Abstract Typ
                  tbody
                    tr(v-for="s in u.subs")
                      td {{s.old_id}}
                      td {{s.detail.title || 'kein Titel vorhanden'}}
                      td {{s.type}}
                      td {{s.detail.type}}
                p.has-text-centered(v-else) Keine Einträge vorhanden
              .highlight
                p.light.has-text-centered Teilnahmen
                table.table.is-narrow.is-fullwidth(v-if="u.atts.length > 0")
                  thead
                    tr
                      th Name
                      th Status
                      th Actions
                  tbody
                    tr(v-for="a in u.atts")
                      td {{a.person.first_name + ' ' + a.person.last_name}}
                      td {{a.status}}
                      td
                        button.button.is-danger(@click="set_paid(a)") Bezahlt setzen
                p.has-text-centered(v-else) Keine Einträge vorhanden


</template>

<script>
import {getPreloadData} from '../js/tools';
import _ from 'lodash';

let preload = getPreloadData();


export default {
  name: 'isAdminTable',
  data() {
    return {
      users: preload.users,
      active_user: "",
      filters: {
        has_submissions_or_drafts: false,
        has_submissions: false,
        has_attendances: false,
        has_paid_attendances: false,
      }
    }
  },
  computed: {

    filtered_users() {
      let has_text_filter = !_.isEmpty(this.filters.text);
      let filter_old_id = has_text_filter ? parseInt(this.filters.text) : false;
      let filter_text = has_text_filter ? this.filters.text.toLowerCase() : false;

      let b = preload.users.filter((x) => {
        if (this.filters.has_submissions_or_drafts) {
          if (x.subs.length <= 0) {
            return false
          }
        }

        if (this.filters.has_submissions) {
          let finalized_subs = _.filter(x.subs, 'submitted');
          if (finalized_subs <= 0) {
            return false
          }
        }

        if (this.filters.has_attendances) {
          if (x.atts.length <= 0) {
            return false
          }
        }

        if (this.filters.has_paid_attendances) {
          let paid_atts = x.atts.filter((y) => {
            return y.status === 'paid'
          });
          if (paid_atts.length <= 0) {
            return false
          }
        }


        if (has_text_filter) {
          let subs = _.get(x, 'subs', []);
          let subs_ids = _.map(subs, 'old_id');
          if (_.includes(subs_ids, filter_old_id)) {
            return true;
          }

          let firstName = _.get(x, 'first_name', '[leer]');
          let lastName = _.get(x, 'last_name', '[leer]');
          let org = _.get(x, 'org', '[leer]');
          let match_on_fname = _.includes(firstName.toLowerCase(), filter_text);
          let match_on_lname = _.includes(lastName.toLowerCase(), filter_text);
          let match_on_org = _.includes(org.toLowerCase(), filter_text);

          if (!(match_on_fname || match_on_lname || match_on_org)) {
            return false;
          }
        }

        return true;
      });


      return b;

    }
  },
  methods: {
    user_count_submitted(user) {
      let submitted = _.filter(user.subs, 'submitted');
      return submitted.length;
    },
    user_count_paid(user) {
      let paid_atts = user.atts.filter((y) => {
        return y.status === 'paid'
      });
      return paid_atts.length;
    },
    dlExport(kind, type) {
      let url;
      if (kind === 'pdfs') {
        url = `/admin/export/${kind}/`;
      } else {
        url = `/admin/download/${kind}/${type}`;
      }
      window.open(url, '_blank');
    },
    get_access_link(u) {
      return `/#/${u.access_code}`
    },
    set_active(u) {
      if (this.active_user !== u._id) {
        this.active_user = u._id
      } else {
        this.active_user = ''
      }
    },
    set_paid(a) {
      this.$dialog.confirm({
        title: 'Status ändern',
        message: 'Sind Sie sicher, dass Sie diese Teilnahme auf <b>bezahlt</b> setzen möchten? Dies löst alle damit verbundenen E-Mails (wieder?) aus.',
        confirmText: 'Ja, sicher!',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          let loadingComponent = this.$loading.open();
          this.$http.get(`/api/payment/${a._id}/paid`).then(
              (x) => {
                this.$toast.open('Erfolgreich!');
                a.status = 'paid';
                loadingComponent.close();
              },
              (x) => {
                console.log('error', x);
                this.$toast.open("Fehler!");
                this.code = '';
                loadingComponent.close();
              });


        }
      })
    }
  },
  mounted() {
  }
}


</script>

<style lang="sass" scoped>
table.main
  > tbody > tr
    &:not(.inner_table)
      &:hover
        &:not(.is-selected)
          background-color: #f8f8f8
        cursor: pointer

    > td
      border: none

  tr.inner_table
    > td
      padding: 1rem 2rem

  .highlight
    > p
      font-weight: bold
    border-left: 1px #00AFF2 solid
    padding: 0 1rem

    .light
      background-color: #f8f8f8
</style>

import {withParams} from 'vuelidate/lib/validators/common'
import {parseInt} from "lodash";

const year = (value) => {
    let valueInt = parseInt(value);
    return valueInt > 1900 && valueInt < 2050
};

const month = (value) => {
    let valueInt = parseInt(value);
    return valueInt >= 1 && valueInt <= 12
};


const monthYearTimeRange = withParams({type: 'monthYearTimeRange'}, value => {
    // not required by default
    if(_.isEmpty(value)) return true;


    let regExp = /^(\d{2}) (\d{4}) - (\d{2}) (\d{4})$/;
    let matchesFormat = regExp.test(value);
    if (!matchesFormat) return false;

    let [wholeMatch, m1, y1, m2, y2] = value.match(regExp);

    let validYears = year(y1) && year(y2);
    let validMonths = month(m1) && month(m2);
    return matchesFormat && validYears && validMonths;
});

const monthYear = withParams({type: 'monthYear'}, value => {
    // not required by default
    if(_.isEmpty(value)) return true;

    let regExp = /^(\d{2}) (\d{4})$/;
    let matchesFormat = regExp.test(value);
    if (!matchesFormat) return false;

    let [wholeMatch, m1, y1] = value.match(regExp);

    let validYears = year(y1);
    let validMonths = month(m1);
    return matchesFormat && validYears && validMonths;
});

const equalToString = withParams({type: 'equalToString'}, value => {
    return value === 'poster'
});

export {monthYearTimeRange, equalToString, monthYear}
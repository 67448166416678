<template>
    <b-field grouped>
        <b-upload v-model="files" :disabled="this.loading" :accept="accept">
            <a class="button is-primary" :class="{'is-loading': this.loading }">
                <b-icon icon="upload"></b-icon>
                <span>Hochladen</span> </a>
        </b-upload>
        <button class="button is-warning" :class="{'is-loading': this.loading }" @click="resetUpload()">
            <b-icon icon="trash"></b-icon>
            <span>Löschen</span> </button>
    </b-field>
</template>


<script>
    export default {
        props:{
            filename: {
                type: String
            },
            accept: {
                type: String
            },
            resize: {
                type: Boolean
            }
        },
        data() {
            return {
                files: [],
                loading: false,
            }
        },
        methods: {
            uploadFile(file) {
                let formData = new FormData();
                // formData.append('_token', this.token); // just the csrf token
                formData.append('file', file);
                formData.append('resize', this.resize);
                this.$http.post('/api/upload', formData).then((response) => {
                    console.log('File sent...'); // this block is never triggered
                    console.log(response);
                    this.loading = false;
                    this.$emit('input', response.body.filename)

                }, (response) => {
                    console.log('Error occurred...');
                });
            },
            resetUpload() {
                this.$emit('input', '');
            }
        },
        watch: {
            files(val) {
                console.log('files changed');
                if(_.isArray(val) && !_.isEmpty(val)) {
                    this.loading = true;
                    this.uploadFile(val[0])
                }
            }
        },
        computed: {
            loading_class() {
                return this.loading ? 'is-loading is-disabled' : ''
            }
        }
    }
</script>
<template lang="pug">
  include ../../views/mixins/bulma
  div.form.columns
    .column.is-8-desktop.is-offset-2-desktop
      h2.title.is-2 Teilnehmende Person
      div
        is-contact-person(:horizontal="true", @invalid="updateValue('invalid.person', $event)", @input="updateValue('att.person', $event)", :user-data="att.person")
        info-text Bitte füllen Sie alle Felder mit * aus.
        sk-show-errors(v-model="$v.att.person")

      h2.title.is-2 Teilnahme am Programm
      p
        tr-text(trkey="att-teilnahme-text")
      div.day(v-for="d in days")
        h2.title.is-3
          tr-text(:trkey="d.id + '-title'")
        div.event(v-for="e of d.events")
          b-checkbox(:disabled="e.disabled || disabled_by_exclusion(e)" v-model="att.events[e.id]")
            tr-text(:trkey="e.id + '-text'")
          info-text( kind="warning" v-if="disabled_by_exclusion(e)") Deaktiviert: Parallelsession gewählt!
          template(v-if="!disabled_by_exclusion(e)")
            info-text(:trkey="e.id + '-info'")
            info-text(kind="warning" :trkey="e.id + '-warn'")

      br

      h3.title.is-3
        tr-text(trkey="att-allgemein-header")
      .content
        tr-text(trkey="att-allgemein")

      h2.title.is-2 Sonstiges
      h3.title.is-3 Unkostenbeitrag
      .content
        tr-text(trkey="att-unkosten")
      .field
        b-switch(v-model='att.student', :disabled="att_is_paid") {{ att.student ? 'Ja, ich bin Student:in und möchte einen Nachlass bekommen!' : 'Ich bin kein:e Student:in!' }}

      .div(v-if="att.student")
        .content
          p Bitte laden Sie ein Beweisdokument hoch, welches Ihren Stand als Student:in belegt (Studiennachweis etc.).

        is-field(v-if="att.proof", label="Aktuelle Datei")
          a(target="_blank", :href="'/uploads/' + att.proof") Datei in einem neuem Fenster öffnen

        .field
          sk-upload(v-model="att.proof", :resize="false", accept=".jpg,.jpeg,.png,.pdf")
          info-text Erlaubte Dateiformate sind JPEG, PNG und PDF.
          sk-show-errors(v-model="$v.att.proof")

      h3.title.is-3 Preis: {{price_current}}€
      div(v-if="price_current > 0")
        .field
          b Rechnung
          info-text(trkey="att-rechnung-info")

        .field
          p Bitte geben Sie hier Ihre Anschrift an, die auf der Rechnung stehen soll!
          b-field(label="Adresse")
            b-input(type="textarea", v-model="att.rechnung_adresse")
          sk-show-errors(:value="$v.att.rechnung_adresse")

      hr




      div
        .field
          info-text(kind="danger", v-if="$v.$invalid") Sie können Ihre Auswahl erst bestätigen, wenn alle Angaben korrekt ausgefüllt wurden
          info-text(kind="danger", v-if="!something_selected") Bitte wählen sie zumindest eine Session!
          info-text(kind="warning") Nach dem Bestätigen einer Teilnahme sind keine weiteren Änderungen möglich!
          info-text(kind="warning" trkey="att-bottom-warn-1" )
          info-text(kind="warning" trkey="att-bottom-warn-2" )

        .field
          info-text(kind="danger", v-if="$v.$invalid") Sie können erst bezahlen, wenn alle Angaben korrekt ausgefüllt wurden.

      template(v-if="!att_is_paid")
        .field
          input.button.is-light(type="button", @click="save(false)", value="Entwurf Speichern und Schließen")
          | &nbsp;
          input.button.is-success(v-if="price_current === 0", type="button", @click="save(true)", value="Teilnahme Bestätigen", :disabled="$v.$invalid")
          input.button.is-success(v-else, type="button", @click="save(true)", value="Weiter zur Bezahlung", :disabled="$v.$invalid")




      template(v-if="is_admin")
        br
        +message("ADMIN", 'danger')
          .field
            b-switch(v-model='att.freikarte') {{ att.freikarte ? 'Freikarte' : 'Keine Freikarte' }}
          .field
            input.button.is-warning(type="button", @click="att.status = 'paid'", value="Auf Bezahlt schalten" v-if="!att_is_paid")
            input.button.is-warning(type="button", @click="att.status = null", value="Auf Unbezahlt schalten" v-if="att_is_paid")
            | &nbsp;
          .field
            input.button.is-danger(type="button", @click="save(false)", value="Als Admin speichern")
            | &nbsp;


</template>

<script>
import _ from 'lodash'
import {getPreloadData} from '../js/tools'
import {required, email, numeric, minValue} from 'vuelidate/lib/validators'
import InfoText from "./info-text";

const preload_data = getPreloadData();
const labels = {
  title: 'Titel',
  gefoerdert: 'Ist das Projekt gefördert?',
  foerderer: 'Förderer des Projekts',
  projektakronym: 'Projektakronym',
  call: 'Call',
  laufzeit: 'Laufzeit',
  detail_type: 'Bevorzugte Art der Präsentation'
};

import data from '../../config/events'

const days = data.days;
const events = _.flatten(days.map(x => x.events));
const events_by_id = _.keyBy(events, 'id');

export default {
  components: {InfoText},
  props: {},
  data() {
    const init_objects = {
      events: _.chain(events_by_id).mapValues(x => undefined).value(),
    };

    let data_obj = {
      is_admin: preload_data.is_admin,
      is_preloaded: false,
      days,
      att: {
        person: {},
        events: init_objects.events,
        form_valid: false,
        price: 0,
        status: null,
        student: undefined,
        proof: '',
        rechnung: undefined,
        rechnung_adresse: '',
      },
      static: {
        info: "Bei Interesse, Fragen und Anmerkungen wenden Sie sich bitte an <a href=\"mailto:angelika.wolf@ccca.ac.at\">angelika.wolf@ccca.ac.at</a>, oder <a href=\"mailto:adelheid.weiland@stmk.gv.at\">adelheid.weiland@stmk.gv.at</a>"
      }
    };

    if (preload_data.att) {
      data_obj.att = _.assign(data_obj.att, preload_data.att);
      data_obj.is_preloaded = true;
    }
    return data_obj;
  },
  methods: {
    updateValueHelper(key, v_key, val) {
      _.set(this, key, val);
      let v_model = _.get(this.$v, v_key, false);
      if (v_model) {
        console.log('touching model');
        v_model.$touch()
      }
    },
    updateValue(key, val) {
      console.log('form::event update', key, val);
      this.updateValueHelper(key, key, val)
    },
    save(continue_with_payment) {
      this.att.price = this.price_current;
      this.$http.post('', this.att).then((resp) => {
        console.log(resp);
        if (continue_with_payment) {
          window.location.href = `/account/attendance/pay/${resp.body.data._id}/`
        } else {
          window.location.href = `/account/`
        }
      });
    },
    disabled_by_exclusion(event) {
      if (!event.exclusive_with) return false;
      for (const ex of event.exclusive_with) {
        if (this.att.events[ex] === true) {
          return true;
        }
      }
      return false;
    }
  },
  computed: {
    form_invalid() {
      return this.$v.$invalid
    },
    something_selected() {
      for (const e of events) {
        if (this.att.events[e.id]) return true;
      }
      return false;
    },
    nonfree_selected() {
      let count = 0;
      for (const d of this.days) {
        if (d.free) continue;
        for (const e of d.events) {
          if (e.free) continue;
          if (this.att.events[e.id]) {
            count++;
          }
        }
      }
      return count;
    },
    price_current() {
      if(this.att.freikarte) return 0;
      let base_price = this.att.student ? 60 : 100;
      let price = 0;
      let extras = 0;

      if (this.nonfree_selected > 0) {
        price += base_price
      }
      return price + extras
    },
    att_is_paid() {
      return this.att.status === 'paid'
    },

  },
  validations() {
    let validations = {
      att: {
        person: {
          first_name: {required},
          last_name: {required},
          email: {required, email},
          phone: {numeric}
        },
        events: {}
      },
      something_selected: {required}
    };

    if (this.att.student) {
      validations.att.proof = {required}
    }

    if (this.price_current > 0) {
      validations.att.rechnung_adresse = {required}
    }

    return validations
  },

  watch: {
    // track form validity in database object
    form_invalid(newValue) {
      this.att.form_valid = !newValue;
    },

    // if price drops to zero, unset 'rechnung'
    // can not be set while 0 because it is hidden
    price_current(newVal) {
      if (newVal === 0) {
        this.att.rechnung = false;
      }
    }
  }
}
</script>

<style lang="scss">
.checkbox[disabled], .radio[disabled] {
  color: #2d2d2d;
}
</style>

<script>
    import Buefy from 'buefy';
    export default {
        name: 'isAutocomplete',
        extends: Buefy.Autocomplete,
        created() {},
        watch: {
            newValue() {
                if(this.loading) {
                    console.log('data update while loading');
                    this.isActive = false;
                }
            }
        }
    }
</script>
<template>
    <div v-cloak>
        <div v-if="!logged_in">

        </div>
        <div v-else>
            <section class="section">
                <h2 class="title is-2">Ihre Einreichungen</h2>
                <b-table :data="tableDataSimple" :row-class="rowClass()">

                    <template slot-scope="props">
                        <b-table-column label="ID" width="40" numeric>
                            {{ props.row.id }}
                        </b-table-column>

                        <b-table-column label="First Name">
                            {{ props.row.first_name }}
                        </b-table-column>

                        <b-table-column label="Last Name">
                            {{ props.row.last_name }}
                        </b-table-column>

                        <b-table-column label="Date" centered>
                            {{ new Date(props.row.date).toLocaleDateString() }}
                        </b-table-column>

                        <b-table-column label="Gender">
                            {{ props.row.gender }}
                        </b-table-column>
                    </template>
                </b-table>
            </section>


            <section class="section">
                <h2 class="title is-2">Ihre Anmeldungen zur Teilnahme</h2>
                <b-table :data="tableDataSimple" :row-class="rowClass()">

                    <template slot-scope="props">
                        <b-table-column label="ID" width="40" numeric>
                            {{ props.row.id }}
                        </b-table-column>

                        <b-table-column label="First Name">
                            {{ props.row.first_name }}
                        </b-table-column>

                        <b-table-column label="Last Name">
                            {{ props.row.last_name }}
                        </b-table-column>

                        <b-table-column label="Date" centered>
                            {{ new Date(props.row.date).toLocaleDateString() }}
                        </b-table-column>

                        <b-table-column label="Gender">
                            {{ props.row.gender }}
                        </b-table-column>
                    </template>
                </b-table>
            </section>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                logged_in: false,
                tableDataSimple: [],
            }
        },
        methods: {
            rowClass() {
                return function (row, index) {
                    return row.id === 1 ? 'is-warning' : ''
                }
            }
        },
        created() {
            this.$eventHub.$on('/user/login', (x) => {
                this.logged_in = true
                this.tableDataSimple = [
                    {
                        'id': 1,
                        'first_name': 'Jesse',
                        'last_name': 'Simmons',
                        'date': '2016-10-15 13:43:27',
                        'gender': 'Male'
                    },
                    {
                        'id': 2,
                        'first_name': 'John',
                        'last_name': 'Jacobs',
                        'date': '2016-12-15 06:00:53',
                        'gender': 'Male'
                    },
                    {
                        'id': 3,
                        'first_name': 'Tina',
                        'last_name': 'Gilbert',
                        'date': '2016-04-26 06:26:28',
                        'gender': 'Female'
                    },
                    {
                        'id': 4,
                        'first_name': 'Clarence',
                        'last_name': 'Flores',
                        'date': '2016-04-10 10:28:46',
                        'gender': 'Male'
                    },
                    {
                        'id': 5,
                        'first_name': 'Anne',
                        'last_name': 'Lee',
                        'date': '2016-12-06 14:38:38',
                        'gender': 'Female'
                    }
                ];

            });
            this.$eventHub.$on('/user/logout', (x) => {
                this.logged_in = false;
                this.tableDataSimple = []
            });
        }
    }
</script>

<style lang="sass">
    tr.is-warning
        background-color: yellow

</style>
import Vue from 'vue'
import Buefy from 'buefy'
import VueLidate from 'vuelidate'
import vue_resource from 'vue-resource'

// setup global event bus
Vue.prototype.$eventHub = new Vue();


Vue.use(Buefy, {
    defaultIconPack: 'fa'
});
Vue.use(vue_resource);
Vue.use(VueLidate);

Vue.component('login-widget', require('./vue/login-widget.vue').default);
Vue.component('nav-bar', require('./vue/nav-bar.vue').default);
Vue.component('user-form', require('./vue/user-form.vue').default);
Vue.component('user-items', require('./vue/user-items.vue').default);
Vue.component('is-input', require('./vue/form-fields/field-input.vue').default);
Vue.component('is-field', require('./vue/buefy-overrides/Field.vue').default);
Vue.component('is-autocomplete', require('./vue/buefy-overrides/Autocomplete.vue').default);
Vue.component('sk-field-autocomplete', require('./vue/form-fields/field-autocomplete').default);
Vue.component('is-user-fields', require('./vue/user-fields.vue').default);
Vue.component('is-contact-person', require('./vue/contact-person-form.vue').default);
Vue.component('sk-form-submission-2018', require('./vue/form-submission.vue').default);
Vue.component('sk-form-attendance-2018', require('./vue/form-attendance.vue').default);
Vue.component('sk-show-errors', require('./vue/form-show-errors').default);
Vue.component('sk-info-text', require('./vue/info-text').default);
Vue.component('sk-textarea', require('./vue/form-fields/field-textarea').default);
Vue.component('sk-upload', require('./vue/form-fields/field-upload').default);
Vue.component('sk-delete-item', require('./vue/delete-item-link').default);
Vue.component('sk-admin-table', require('./vue/admin-table').default);
Vue.component('tr-text', require('./vue/tr-text').default);

new Vue({
    el: '#app',
});

if (module.hot) {
    console.log('module.hot found');
    module.hot.accept();
    module.hot.dispose(function () {
        clearInterval(timer);
    });
}

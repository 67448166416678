<template>
  <div>
    <div v-if="is_admin"><div class="has-text-warning">[{{ trkey }}] <slot></slot></div></div>
    <div v-if="translated" v-html="translated"></div>
  </div>
</template>

<script>
import {getPreloadData, translations} from "../js/tools";

let preloadData = getPreloadData();


export default {
  props: {
    trkey: {required: true},
  },
  data() {
    return {
      is_admin: preloadData.is_admin,
      translations: {},
    }
  },
  computed: {
    translated() {
      return this.translations[this.trkey]
    }
  },
  created() {
    translations.then(x=> this.translations = x)
  },
}
</script>
import _ from 'lodash'
import axios from 'axios'
import orgs from '../../config/member-institutions.json'

const messages = {
    required: 'Pflichtangaben fehlen!',
    minLength: 'Bitte geben Sie zumidest {0} Zeichen ein!',
    minLengthArray: 'Die Mindestauswahl ist {0} Option/en!',
    maxLength: 'Bitte geben Sie nicht mehr als {0} Zeichen ein!',
    maxValueRows: 'Bitte geben Sie nicht mehr als {0} Zeilen ein!',
    minValueItems: 'Bitte wählen Sie zumindest {0} Möglichkeit(en)!',
    email: 'Bitte geben Sie eine korrekt formatierte E-Mail Adresse ein!',
    numeric: 'Bitte geben sie nur Zahlen ein!',
    monthYearTimeRange: 'Eingabe entspricht nicht dem geforderten Format!'
};

export function getFieldErrorMessage(fieldParamArray) {
    let paramName = fieldParamArray.name;

    switch (paramName) {
        case 'minLengthArray':
            return messages.minLengthArray.replace('{0}', fieldParamArray.params.min);
        case 'minLength':
            return messages.minLength.replace('{0}', fieldParamArray.params.min);
        case 'maxLength':
            return messages.maxLength.replace('{0}', fieldParamArray.params.max);
        case 'maxValueRows':
            return messages.maxValueRows.replace('{0}', fieldParamArray.params.max);
        case 'minValueItems':
            return messages.minValueItems.replace('{0}', fieldParamArray.params.min);
        default:
            return messages[paramName] ? messages[paramName] : '';
    }
}


export function getOrgNamesArray() {
    return _.map(orgs, (x) => `${x.name} (${x.name_short})`);
}


let jsonString = document.getElementById('preload_data').innerText;
const preload_data = JSON.parse(jsonString);
console.log({preload_data});

export function getPreloadData() {
    return preload_data;
}

export function calculateLengthRows(text, rowLength = 98) {
    let total_length = 0;
    let parts = text.split(/\r\n|\r|\n/);
    for (let part of parts) {
        let length = part.length;
        let multiples_of_x = _.ceil(length / rowLength);
        if (multiples_of_x === 0) {
            multiples_of_x = 1;
        }
        total_length += multiples_of_x;
    }
    return total_length;
}

export const translations = axios.get('/api/translations').then(x => x.data);



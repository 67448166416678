<template>
  <div class="container">
    <nav class="navbar">
      <div class="navbar-brand">
        <div class="navbar-burger burger" @click="menu_open = !menu_open" :class="{'is-active': menu_open}">
          <span></span><span></span><span></span></div>
      </div>
      <div class="navbar-menu" :class="{'is-active': menu_open}">
        <div class="navbar-start">
          <a class="navbar-item" href="/">Startseite</a>
          <a v-if="loggedIn" href="/account" class="navbar-item">Mein Account</a>
          <a v-if="!loggedIn && (active_reg)" href="/account" class="navbar-item is-warning">Registrierung</a>
          <a v-if="is_admin" href="/admin" class="navbar-item">Admin Bereich</a>
          <a v-if="is_admin" href="/admin/logout" class="navbar-item">Admin Abmelden</a>
        </div>
        <div class="navbar-end">
          <div class="navbar-item">
            <login-widget></login-widget>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import {getPreloadData} from '../js/tools'

let data = getPreloadData();

export default {
  data() {
    return {
      active_reg: data.active.registration,
      active_sub: data.active.submission,
      active_att: data.active.attendance,
      is_admin: data.is_admin,
      loggedIn: data.logged_in,
      menu_open: false,
    }
  },
}
</script>